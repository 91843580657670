import * as React from 'react';
import { connect } from 'react-redux';

import { ISetLocationPageParams } from '../../actions/location';
import { getOffersData } from '../../actions/offersData';
import { Container } from '../../components/Container';
import { Pagination } from '../../components/Pagination';
import {
  NEWBUILDINGS_GK_SUGGEST_COUNT,
  PATH_PREFIX_NON_FIRST_PAGE,
  QUERY_PARAM_NAME_PAGE,
} from '../../constants/config';
import { SIZE_16, SIZE_32, SIZE_40, SIZE_8 } from '../../constants/sizes';
import { IApplicationState } from '../../types/redux';
import { TTypedThunkDispatch } from '../../types/thunk';
import { PrintButtonContainer } from '../PrintButton';

interface IOffersFooterStoreProps {
  currentPage: number;
  maxPage: number;
  queryString: string;
  geoPath: string;
  canUseHiddenBase: boolean;
}

interface IOffersFooterDispatchProps {
  setPage(params: ISetLocationPageParams): void;
}

type TOffersFooterProps = IOffersFooterStoreProps & IOffersFooterDispatchProps;

class OffersFooter extends React.PureComponent<TOffersFooterProps> {
  public render() {
    const { currentPage, maxPage, setPage, canUseHiddenBase } = this.props;

    return (
      <Container
        backgroundColor="white"
        dataMark="OffersFooter"
        borderColor="gray_1"
        borderStyle="solid"
        padding={`${SIZE_32} ${SIZE_16} ${SIZE_40}`}
        borderTopWidth={0}
        noPrint
      >
        <Container display="flex" justifyContent="space-between" alignItems="center">
          {maxPage && maxPage > 1 ? (
            <Pagination
              currentPage={currentPage}
              maxPage={maxPage}
              urlCreator={this.urlCreator}
              onClick={({ page, url }) => setPage({ page, path: url, canUseHiddenBase })}
            />
          ) : (
            <div />
          )}
          <Container display="flex">
            <Container margin={`0 ${SIZE_8} 0`} dataMark="Print">
              <PrintButtonContainer />
            </Container>
          </Container>
        </Container>
      </Container>
    );
  }

  private urlCreator = (page: number) => {
    const { queryString, geoPath } = this.props;

    const query = (queryString && `${queryString}&`) || '';
    const url = `${geoPath ? `/${geoPath}` : ''}/${PATH_PREFIX_NON_FIRST_PAGE}?${query}${QUERY_PARAM_NAME_PAGE}=${page}`;

    return url;
  };
}

function mapStateToProps(state: IApplicationState): IOffersFooterStoreProps {
  const { offersData, location } = state;
  const { seo, total, queryString } = offersData;

  const maxPage = Math.ceil((total || 0) / NEWBUILDINGS_GK_SUGGEST_COUNT);

  return {
    currentPage: location.page,
    geoPath: location.geoPath,
    maxPage,
    queryString: (seo && seo.query) || queryString,
    canUseHiddenBase: state.user.isAuthenticated && state.user.permissions.canUseHiddenBase,
  };
}

function mapDispatchToProps(dispatch: TTypedThunkDispatch): IOffersFooterDispatchProps {
  return {
    setPage: (params: ISetLocationPageParams) => {
      dispatch(getOffersData(params));
    },
  };
}
export const OffersFooterContainer = connect(mapStateToProps, mapDispatchToProps)(OffersFooter);
